import req from '@/api/request'

// 查询
export function getCrabTicket(query) {
  return req.post('/getCrabTicket', query)
}

// 兑换
export function exchangeCrabTicket(query) {
  return req.post('/exchangeCrabTicket', query)
}

// 获取提示信息
export function getDefaultMessage(query) {
  return req.post('/getDefaultMessage', query)
}

// 查询最大发货时间
export function getMaxDate(query) {
  return req.post('/getMaxDate', query)
}

// 查询最小发货时间
export function getMinDate(query) {
  return req.post('/getMinDate', query)
}

// 查询页面开关
export function getMsgTitle() {
  return req.post('/getMsgTitle')
}
